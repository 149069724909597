@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/src/assets/fonts/PPNeueMontreal-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/src/assets/fonts/PPNeueMontreal-Book.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/src/assets/fonts/PPNeueMontreal-Italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/src/assets/fonts/PPNeueMontreal-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/src/assets/fonts/PPNeueMontreal-SemiBolditalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'PP Neue Montreal';
  src: url('/src/assets/fonts/PPNeueMontreal-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'PP Neue Montreal Mono';
  src: url('/src/assets/fonts/PPNeueMontrealMono-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
