@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./fonts.css');

body {
  font-family: 'PP Neue Montreal';
}

@media (width <=1600px) {
  html {
    font-size: 14px;
  }
}

.mono {
  font-family: 'PP Neue Montreal Mono';
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}
